import ECodeValidator from "./eCodeValidator";
import { BasketValidatorData } from "./baseValidator";
import logger from "../../loggers";

class BasketValidator implements ERSValidation.IMultiValidator {
  private _validators: ERSValidation.IValidator[];
  constructor() {
    this._validators = [];
  }

  addValidator = (validator: ERSValidation.IValidator) => {
    this._validators.push(validator);
  };

  validate = (): ERSValidation.ValidationResult[] => {
    return this._validators
      .sort((prev, next) => prev.order - next.order)
      .map((v) => v.validate());
  };
}

class ValidatorLogger {
  private _name: string;
  private _logs: Record<string, any[]>;
  constructor(name: string) {
    this._name = name;
    this._logs = {};
  }
  begin = () => {
    this._logs["BEGIN"] = [`%c%s - BEGIN`, "color: blue", this._name];
  };
  data = (data: Record<string, any>, index: number = 1, total: number = 1) => {
    const args: any[] = [
      `%c%s - DATA (%i/%i):`,
      "color: fuchsia",
      this._name,
      index,
      total
    ];
    Object.entries(data).forEach((item) => {
      args.push(`\n${item[0]}: `);
      args.push(item[1]);
    });
    this._logs[`DATA_${index}`] = args;
  };
  valid = () => {
    this._logs["VALID"] = [`%c%s - VALID`, "color: green", this._name];
    Object.entries(this._logs).forEach(([key, args]) => {
      logger.debug(...args);
    });
  };
  invalid = (message: string) => {
    this._logs["INVALID"] = [
      `%c%s - INVALID\nmessage: %s`,
      "color: red",
      this._name,
      message
    ];
    Object.entries(this._logs).forEach(([key, args]) => {
      logger.error(...args);
    });
  };
}

export { BasketValidatorData, ECodeValidator, ValidatorLogger };

export const BASKET_ERRORS = {
  GENERIC: "basket.validation.generic-error",
  TOTAL: "basket.validation.total",
  ECODE_FORMAT: "basket.validation.ecode_format",
  INVALID_ITEM: "basket.validation.invalid_item",
  INVALID_RETAILER: "basket.validation.invalid_retailer"
};

export default BasketValidator;
