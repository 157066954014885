import { BASKET_ERRORS, ValidatorLogger } from ".";
import BaseValidator, { BasketValidatorData } from "./baseValidator";

class CardTypeValidator extends BaseValidator {
  name = "CardTypeValidator";
  order: number = 1;
  private _logger: ValidatorLogger;

  constructor(validatorData: BasketValidatorData) {
    super(validatorData);
    this._logger = new ValidatorLogger(this.name);
  }

  validate = (): ERSValidation.ValidationResult => {
    this._logger.begin();
    if (this._validatorData.apiRetailer === null) {
      this._logger.invalid(BASKET_ERRORS.GENERIC);
      return { isValid: false, message: BASKET_ERRORS.GENERIC };
    }

    const basketItems = this._validatorData.basket;
    const { products } = this._validatorData.apiRetailer;

    const items = basketItems.map((i) => ({
      retailerId: i.retailer.referenceId,
      cardType: i.cardType
    }));

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const product = products.find((p) => p.id === item.retailerId);

      this._logger.data(
        { basketItem: item, product: product },
        i + 1,
        items.length
      );

      if (!product || !product.cardTypes) {
        this._logger.invalid(BASKET_ERRORS.INVALID_ITEM);
        return { isValid: false, message: BASKET_ERRORS.INVALID_ITEM };
      }

      const cardType = product.cardTypes.find((c) => c.id === item.cardType.id);
      if (!cardType) {
        this._logger.invalid(BASKET_ERRORS.INVALID_ITEM);
        return { isValid: false, message: BASKET_ERRORS.INVALID_ITEM };
      }

      if (
        cardType.id !== item.cardType.id ||
        cardType.referenceId !== item.cardType.referenceId ||
        cardType.minAmount !== item.cardType.minAmount ||
        cardType.maxAmount !== item.cardType.maxAmount ||
        cardType.denomination !== item.cardType.denomination
      ) {
        this._logger.invalid(BASKET_ERRORS.INVALID_ITEM);
        return { isValid: false, message: BASKET_ERRORS.INVALID_ITEM };
      }
    }

    this._logger.valid();
    return { isValid: true };
  };
}

export default CardTypeValidator;
