import { GatsbyBrowser } from "gatsby";
import Provider from "./src/components/Provider";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element
}) => <Provider>{element}</Provider>;

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {
  if (window.maintenance && typeof window.maintenance === "function") {
    window.maintenance();
  }
};
