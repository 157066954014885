import MicroCopy from "@edenred/micro-copy";
import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo } from "react";

type Props = {
  children: React.ReactNode;
};

export default function IntegratedMicroCopy({ children }: Props) {
  const {
    allContentfulResource: { nodes: data }
  } = useStaticQuery<Pick<GatsbyTypes.Query, "allContentfulResource">>(query);
  const values = useMemo<Record<string, string>>(() => {
    return data.reduce((carry, { key, value }) => {
      return {
        ...carry,
        // @note: This project uses general `Resources` used for microcopy.
        // All entries are prefixed with "microcopy.", we are removing to be
        // more dev friendly.
        [key!.toLowerCase().replace("microcopy.", "")]: value
      };
    }, {});
  }, [data]);
  return (
    <MicroCopy.Provider values={values} prefix="microcopy.">
      {children}
    </MicroCopy.Provider>
  );
}

const query = graphql`
  fragment Resource on ContentfulResource {
    key
    value
  }
  query AllMicroCopy {
    allContentfulResource(filter: { key: { regex: "/microcopy/" } }) {
      nodes {
        ...Resource
      }
    }
  }
`;
