import { HEADER_ID } from "@edenred/header";
import { globalClasses } from "@edenred/theme";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Close as CloseIcon,
  ErrorOutline as ErrorOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
  WarningAmber as WarningAmberIcon
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { createRef, ReactNode } from "react";
import { isBrowser } from "../hooks/useToken";

type Props = {
  children: ReactNode;
};

export default function IntegratedSnackbar({ children }: Props) {
  const notistackRef = createRef<SnackbarProvider>();
  const header = (isBrowser && document.getElementById(HEADER_ID)) || undefined;

  function handleSnackbarClose(key: SnackbarKey) {
    notistackRef?.current?.closeSnackbar(key);
  }

  return (
    <SnackbarProvider
      action={(key) => (
        <IconButton
          color="inherit"
          onClick={() => handleSnackbarClose(key)}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      )}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      classes={{
        containerRoot: header && globalClasses.headerTopMargin
      }}
      iconVariant={{
        success: <CheckCircleOutlineIcon sx={{ marginInlineEnd: 1 }} />,
        error: <ErrorOutlineIcon sx={{ marginInlineEnd: 1 }} />,
        warning: <WarningAmberIcon sx={{ marginInlineEnd: 1 }} />,
        info: <InfoOutlinedIcon sx={{ marginInlineEnd: 1 }} />
      }}
      maxSnack={3}
      ref={notistackRef}
    >
      {children}
    </SnackbarProvider>
  );
}
