import {
  CssBaseline,
  GlobalStyles,
  GlobalStylesProps,
  Theme as MuiTheme,
  ThemeOptions as MuiThemeOptions,
  TypographyVariantsOptions,
  useMediaQuery
} from "@mui/material";
import { Breakpoint, createTheme, ThemeProvider } from "@mui/material/styles";
import { ReactNode } from "react";
import "./fonts/index.module.scss";

export type ThemeOptions = MuiThemeOptions & {
  typography?: TypographyVariantsOptions;
};

export const globalClasses = {
  headerTopMargin: "Spacing-headerTopMargin"
};

const globalStyles: GlobalStylesProps["styles"] = {
  [`.${globalClasses.headerTopMargin}`]: { marginTop: 70 },
  "@page": { margin: 0 },
  "@media print": {
    ".MuiButton-root": { display: "none !important" },
    ".MuiGrid-root": { marginBottom: "0 !important" }
  }
};

export const getTheme = () => {
  const defaultTheme: ThemeOptions = {
    palette: {
      primary: {
        main: "#000000",
        light: "#7986cb",
        dark: "#303f9f",
        contrastText: "#ffffff"
      },
      error: {
        main: "#f44336",
        light: "#f88078",
        dark: "#e31b0c",
        contrastText: "#ffffff"
      }
    },
    typography: {
      // @note: heading typographies are specified further down, after theme creation
      fontFamily: "Open Sans, sans-serif",

      subtitle1: {
        fontSize: "16px",
        letterSpacing: "0.15px",
        lineHeight: "28px"
      },
      subtitle2: {
        fontSize: "14px",
        fontWeight: 600,
        letterSpacing: "0.1px",
        lineHeight: "21.98px"
      },
      body1: {
        fontSize: "16px",
        letterSpacing: "0.15px",
        lineHeight: "24px"
      },
      body2: {
        fontSize: "14px",
        letterSpacing: "0.15px",
        lineHeight: "20.02px"
      },
      caption: {
        fontSize: "12px",
        letterSpacing: "0.4px",
        lineHeight: "19.92px"
      },
      overline: {
        fontSize: "12px",
        letterSpacing: "1px",
        lineHeight: "31.92px"
      },
      button: {
        fontWeight: 600
      }
    },
    shape: {
      borderRadius: 8
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 740,
        md: 900,
        lg: 1216
      }
    }
  };
  const theme = createTheme(defaultTheme);

  // @note: Typographies that depend on breakpoints have to be specified after theme creation
  theme.typography.h1 = {
    fontSize: "96px",
    fontWeight: 400,
    letterSpacing: "-1.5px",
    lineHeight: "112.03px",

    [isBelowMobileBreakpoint(theme)]: {
      fontSize: "64px",
      letterSpacing: "-0.5px",
      lineHeight: "80px"
    }
  };
  theme.typography.h2 = {
    fontSize: "60px",
    fontWeight: 600,
    letterSpacing: "-0.5px",
    lineHeight: "72px",

    [isBelowMobileBreakpoint(theme)]: {
      fontSize: "48px",
      lineHeight: "65.37px"
    }
  };
  theme.typography.h3 = {
    fontSize: "48px",
    fontWeight: 400,
    lineHeight: "56px",

    [isBelowMobileBreakpoint(theme)]: {
      fontSize: "34px",
      lineHeight: "48px"
    }
  };
  theme.typography.h4 = {
    fontSize: "34px",
    fontWeight: 400,
    letterSpacing: "0.25px",
    lineHeight: "41.99px",

    [isBelowMobileBreakpoint(theme)]: {
      fontSize: "26px",
      lineHeight: "36px"
    }
  };
  theme.typography.h5 = {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32.02px",

    [isBelowMobileBreakpoint(theme)]: {
      fontSize: "20px",
      lineHeight: "30px"
    }
  };
  theme.typography.h6 = {
    fontSize: "20px",
    fontWeight: 600,
    letterSpacing: "0.15px",
    lineHeight: "32px",

    [isBelowMobileBreakpoint(theme)]: {
      fontSize: "18px",
      lineHeight: "28px"
    }
  };

  return theme;
};

const isBelowMobileBreakpoint = (theme: MuiTheme) =>
  theme.breakpoints.down("sm");

export const isMobileMediaQuery = () =>
  useMediaQuery(isBelowMobileBreakpoint(getTheme()));

type BreakpointSelect<T> = Record<Exclude<Breakpoint, "xs">, T>;
export const useBreakpointSelect = <T,>(items: BreakpointSelect<T>) => {
  const theme = getTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const lessThanMedium = useMediaQuery(theme.breakpoints.down("md"));
  if (!items) return undefined;
  const largeItem = items["lg"] ?? items["md"] ?? items["sm"];
  const mediumItem = items["md"] ?? largeItem;
  const smallItem = items["sm"] ?? mediumItem;

  if (lessThanSmall) return smallItem;
  else if (lessThanMedium) return mediumItem;
  else return largeItem;
};

type Props = {
  children: ReactNode;
};

const inputGlobalStyles = <GlobalStyles styles={globalStyles} />;

const Theme = ({ children }: Props) => (
  <ThemeProvider theme={getTheme()}>
    <CssBaseline />
    {inputGlobalStyles}
    {children}
  </ThemeProvider>
);

export default Theme;
