import { StyledProps } from "@edenred/types";
import { Container as MuiContainer, ContainerProps } from "@mui/material";

const style = {
  px: {
    xs: 2.5,
    sm: 4
  },
  pb: 0
} as const;

const Container = ({ sxStyle, ...props }: StyledProps<ContainerProps>) => {
  return (
    <MuiContainer sx={{ ...style, ...sxStyle }} maxWidth="lg" {...props}>
      {props.children}
    </MuiContainer>
  );
};

export default Container;
