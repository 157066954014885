import ApiClientBase from "./base";

class ERSClientApi extends ApiClientBase {
  async eCode(
    eCode: string,
    clientReferenceId?: string
  ): Promise<ErsApiTypes.ApiResponse<ErsApiTypes.LoginResponse>> {
    return await this.baseRequest({
      url: `api/ecode`,
      method: "POST",
      data: { eCode, clientReferenceId: clientReferenceId }
    });
  }

  async login(
    eCode: string,
    password?: string
  ): Promise<ErsApiTypes.ApiResponse<ErsApiTypes.TokenResponse>> {
    return await this.baseRequest({
      url: `api/login`,
      method: "POST",
      data: { eCode, password: password }
    });
  }

  async customer(
    name: string
  ): Promise<ErsApiTypes.ApiResponse<ErsApiTypes.CustomerResponse>> {
    return await this.baseRequest({
      url: `api/customer`,
      method: "POST",
      data: { name }
    });
  }

  async retailers(
    authToken: string,
    schemeId: string,
    customerExternalRef?: string,
    originatorId?: number
  ): Promise<ErsApiTypes.ApiResponse<ErsApiTypes.ProductsResponse>> {
    return await this.baseRequest({
      url: `api/retailers`,
      method: "POST",
      data: {
        schemeId,
        customerExternalRef,
        originatorId,
        authToken: authToken
      }
    });
  }

  async checkout(
    data: ErsApiTypes.CreateOrderRequest
  ): Promise<ErsApiTypes.ApiResponse<ErsApiTypes.CreateOrderResponse>> {
    return await this.baseRequest({
      url: `api/checkout`,
      method: "POST",
      data: data
    });
  }

  async logging(
    data: ErsApiTypes.LoggingRequest
  ): Promise<ErsApiTypes.ApiResponse<ErsApiTypes.LoggingResponse>> {
    return await this.baseRequest({
      url: `api/logging`,
      method: "POST",
      data: data,
      skipLogging: true
    });
  }
}

export default ERSClientApi;
