import { createContext, useContext, useMemo, ReactNode } from "react";
import escapeStringRegexp from "escape-string-regexp";

export const MicroCopyContext = createContext<GetMicroCopy>(
  (path, placeholders) => generateDefaultValue(path, placeholders)
);

const generateDefaultValue = (
  path: string,
  placeholders: Record<string, string | number> = {},
  prefix: string = ""
) => {
  let defaultValue = prefix + path;
  const requiredPlaceholders = Object.keys(placeholders);
  if (requiredPlaceholders.length) {
    defaultValue += ` - ${requiredPlaceholders.join(",")}`;
  }
  /* eslint-disable no-console */
  console.warn(`Missing microcopy: ${defaultValue}`);
  return defaultValue;
};

const getMicroCopyFromValues = (
  values: Record<string, string>,
  path: string,
  placeholders: Record<string, string | number> = {},
  prefix?: string
): string =>
  Object.entries(placeholders).reduce((carry, [key, value]) => {
    const toReplace = `{{${key}}}`;
    return carry.replace(
      new RegExp(escapeStringRegexp(toReplace), "g"),
      String(value)
    );
  }, values[path] || generateDefaultValue(path, placeholders, prefix));

type Props = {
  path: string;
  placeholders?: Record<string, string | number>;
};

const MicroCopy = ({ path, placeholders = {} }: Props) => {
  const getMicroCopy = useContext(MicroCopyContext);
  const value: string = getMicroCopy(path, placeholders);

  return <>{value}</>;
};

type ProviderProps = {
  values: Record<string, string>;
  children: ReactNode;
  prefix?: string;
};

export type GetMicroCopy = (
  path: string,
  placeholders?: Record<string, string | number>
) => string;

const generateGetMicroCopy = (
  values: Record<string, string>,
  prefix?: string
) => {
  const getMicroCopyClosure: GetMicroCopy = (path, placeholders) =>
    getMicroCopyFromValues(values, path, placeholders, prefix);

  return getMicroCopyClosure;
};

const MicroCopyProvider = ({ values, children, prefix }: ProviderProps) => {
  const getMicroCopy = useMemo(() => {
    return generateGetMicroCopy(values, prefix);
  }, [values]);
  return (
    <MicroCopyContext.Provider value={getMicroCopy}>
      {children}
    </MicroCopyContext.Provider>
  );
};

MicroCopy.Provider = MicroCopyProvider;

export const useMicroCopyContext = () => {
  const context = useContext(MicroCopyContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};

export default MicroCopy;
