abstract class BaseValidator implements ERSValidation.IValidator {
  protected _validatorData: BasketValidatorData;
  constructor(validatorData: BasketValidatorData) {
    this._validatorData = validatorData;
  }
  abstract name: string;
  abstract order: number;
  abstract validate: () => ERSValidation.ValidationResult;
}

export interface BasketValidatorData {
  basket: ERSTypes.Basket[];
  eCodes: ERSTypes.ECodeData[];
  apiRetailer: ErsApiTypes.ProductsResponse | null;
}

export default BaseValidator;
