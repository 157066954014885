import { BASKET_ERRORS, ValidatorLogger } from ".";
import { ecode } from "../../regex";
import BaseValidator, { BasketValidatorData } from "./baseValidator";

class ECodeValidator extends BaseValidator {
  name = "ECodeValidator";
  order: number = 0;
  private _logger: ValidatorLogger;
  constructor(validatorData: BasketValidatorData) {
    super(validatorData);
    this._logger = new ValidatorLogger(this.name);
  }

  validate = (): ERSValidation.ValidationResult => {
    this._logger.begin();

    const eCodes = this._validatorData.eCodes;
    this._logger.data({ eCodes });

    if (eCodes.some((e) => !ecode.test(e.eCodeAdded))) {
      this._logger.invalid(BASKET_ERRORS.ECODE_FORMAT);
      return { isValid: false, message: BASKET_ERRORS.ECODE_FORMAT };
    }

    this._logger.valid();
    return { isValid: true };
  };
}

export default ECodeValidator;
