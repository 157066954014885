export function roundMoney(amount: number): string {
  const isWhole = amount - Math.floor(amount) === 0;
  return isWhole ? amount.toString() : amount.toFixed(2);
}

export function sumValues(
  carry: number,
  { value }: ERSTypes.Basket | ERSTypes.ECode
): number {
  return carry + value;
}
