exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../../../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */),
  "component---src-templates-basket-tsx": () => import("./../../../src/templates/basket.tsx" /* webpackChunkName: "component---src-templates-basket-tsx" */),
  "component---src-templates-checkout-tsx": () => import("./../../../src/templates/checkout.tsx" /* webpackChunkName: "component---src-templates-checkout-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-order-tsx": () => import("./../../../src/templates/order.tsx" /* webpackChunkName: "component---src-templates-order-tsx" */),
  "component---src-templates-retailer-tsx": () => import("./../../../src/templates/retailer.tsx" /* webpackChunkName: "component---src-templates-retailer-tsx" */),
  "component---src-templates-rewards-tsx": () => import("./../../../src/templates/rewards.tsx" /* webpackChunkName: "component---src-templates-rewards-tsx" */)
}

