import logger from ".";

export const logApiError = <T, K>(
  name: string,
  response: ErsApiTypes.ApiResponse<T>,
  request?: K
) => {
  logger.error(
    `%c %s request is failed\n %crequest: %O\n %cresponse: %O`,
    "color: red",
    name,
    "color: blue",
    request,
    "color: red",
    response
  );
};

export const logApiData = <T, K>(
  name: string,
  response: ErsApiTypes.ApiResponse<T>,
  request?: K
) => {
  logger.debug(
    `%c %s request is successful\n %crequest: %O\n %cresponse: %O`,
    "color: green",
    name,
    "color: blue",
    request,
    "color: red",
    response
  );
};
