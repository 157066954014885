import cookie from "cookie";

export type Token = string | undefined;

export const isBrowser: boolean = typeof document !== "undefined";

const MAX_AGE = 15 * 60; // @note: 15 mins to match server session.

/**
 * This function uses the react hooks pattern to leverage `document.cookie`
 * as state to store an authentication token.
 *
 * Mmmmm, cookies.
 */
export function useToken(init?: string): [Token, (token?: string) => Token] {
  const cookies = isBrowser ? cookie.parse(document.cookie) : {};
  const setToken = (token?: string) =>
    (document.cookie = token
      ? `token=${token};Max-Age=${MAX_AGE}`
      : "token=;Max-Age=0");
  const token = init ? setToken(init) : cookies.token;

  return [token, setToken];
}
