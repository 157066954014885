class ConsoleLogger implements ERSLogging.ILog {
  debug = (...args: any[]) => {
    // eslint-disable-next-line no-console
    console.debug(...args);
  };
  info = (...args: any[]) => {
    // eslint-disable-next-line no-console
    console.info(...args);
  };
  warning = (...args: any[]) => {
    // eslint-disable-next-line no-console
    console.warn(...args);
  };
  error = (...args: any[]) => {
    // eslint-disable-next-line no-console
    console.error(...args);
  };
}

export default ConsoleLogger;
