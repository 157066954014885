import { logApiData, logApiError } from "../loggers/wrappers";

abstract class ApiClientBase {
  public apiUrl: string;
  public baseHeaders: object;
  constructor(apiUrl: string = "") {
    this.apiUrl = apiUrl;
    this.baseHeaders = {};
  }

  async baseRequest<T, K>({
    data,
    url,
    method,
    headers,
    skipLogging
  }: ErsApiTypes.ApiRequest<K, T>): Promise<ErsApiTypes.ApiResponse<T>> {
    let responseDataJson: ErsApiTypes.ApiResponse<T> = {};
    let status: number = 500;
    let fullUrl = url ? `${this.apiUrl}/${url}` : this.apiUrl;
    try {
      const allHeaders: object | undefined =
        headers ??
        (data
          ? {
              "content-type": "application/json;charset=UTF-8"
            }
          : undefined);

      const response = await fetch(fullUrl, {
        method: method,
        body: data ? JSON.stringify(data) : undefined,
        headers: { ...this.baseHeaders, ...allHeaders }
      });

      responseDataJson = (await response.json()) as ErsApiTypes.ApiResponse<T>;

      if (!response.ok || responseDataJson === undefined) {
        status = response.status;
        throw responseDataJson;
      }

      status = response.status;
      if (!skipLogging)
        responseDataJson.error
          ? logApiError<T, K>(fullUrl, responseDataJson, data)
          : logApiData<T, K>(fullUrl, responseDataJson, data);
      return responseDataJson;
    } catch (err) {
      const { data: responseData, error } =
        responseDataJson || ({} as ErsApiTypes.ApiResponse<T>);
      const errorResponse = {
        data: responseData,
        error: {
          status: status,
          message: "An error has ocurred while fetching data",
          error: {
            exception: err instanceof Error ? `${err}` : err,
            error: error
          }
        }
      };

      if (!skipLogging) logApiError(fullUrl, errorResponse, data);
      return errorResponse;
    }
  }
}

export default ApiClientBase;
