export const integer = /\d*/;
export const decimal = /\d*\.?\d{0,2}/;
export const ecode = /^[0-9]{0,50}$/;
export const address = /^([#.0-9a-zA-Z,-]+)(\s{0,1}[#.0-9a-zA-Z,-]+)*$/;
export const mobilePhone =
  /^((((\+|00)([0-9]{1,3})\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/;
export const postCode = /^([a-zA-Z]{1,2}?[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2})$/;
export const name = /^[A-Za-z]+((\s)?(('|-|\.)?([A-Za-z])+))*$/;
export const email =
  /^[a-zA-Z0-9._+'-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
