import Theme from "./Theme";

export type { ThemeOptions } from "./Theme";
export {
  getTheme,
  globalClasses,
  isMobileMediaQuery,
  useBreakpointSelect
} from "./Theme";

export default Theme;
