import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo
} from "react";
import { Data as Retailer } from "../utils/filterAndMergeRetailers";
import { sumValues } from "../utils/money";
import MicroCopyProvider from "./MicroCopy";
import SnackbarProvider from "./Snackbar";
import useLocalStorageState from "../hooks/useLocalStorageState";
import BasketValidator, {
  ECodeValidator,
  BasketValidatorData
} from "../utils/validation/basket";
import CardTypeValidator from "../utils/validation/basket/cardTypeValidator";
import RetailerValidator from "../utils/validation/basket/retailerValidator";
import AmountValidator from "../utils/validation/basket/amountValidator";

type OrderPageData = {
  orderData: ErsApiTypes.CreateOrderResponse;
  basket: ERSTypes.Basket[];
};

export type Props = {
  balance: number;
  basket: ERSTypes.Basket[];
  findInBasket: (
    retailerID: string,
    cardTypeID: number
  ) => ERSTypes.Basket | undefined;
  updateBasket: Dispatch<SetStateAction<ERSTypes.Basket[]>>;
  addBasketItem: (basketItem: ERSTypes.Basket) => void;
  updateBasketItem: (
    basketItem: ERSTypes.Basket,
    editingCardTypeID: number
  ) => void;
  eCodes: ERSTypes.ECodeData[];
  updateECodes: Dispatch<SetStateAction<ERSTypes.ECodeData[]>>;
  customer: ERSTypes.Customer | null;
  updateCustomer: Dispatch<SetStateAction<ERSTypes.Customer | null>>;
  apiRetailer: ErsApiTypes.ProductsResponse | null;
  updateApiRetailer: Dispatch<
    SetStateAction<ErsApiTypes.ProductsResponse | null>
  >;
  retailerLookup: Record<string, Retailer>;
  updateRetailerLookup: Dispatch<SetStateAction<Record<string, Retailer>>>;
  orderPageData: OrderPageData | null;
  updateOrderPageData: Dispatch<SetStateAction<OrderPageData | null>>;
  validateBasket: () => ERSValidation.ValidationResult;
};

export const DEFAULT_CONTEXT_VALUES = {
  balance: 0,
  basket: [],
  findInBasket: () => undefined,
  updateBasket: () => {},
  addBasketItem: () => {},
  updateBasketItem: () => {},
  eCodes: [],
  updateECodes: () => {},
  customer: null,
  updateCustomer: () => {},
  apiRetailer: null,
  updateApiRetailer: () => {},
  retailerLookup: {},
  updateRetailerLookup: () => {},
  orderPageData: null,
  updateOrderPageData: () => {},
  validateBasket: (): ERSValidation.ValidationResult => ({ isValid: true })
};

export const Context = createContext<Props>(DEFAULT_CONTEXT_VALUES);

function isBasketItemMatch(
  item1: ERSTypes.Basket,
  item2: ERSTypes.Basket,
  editingCardTypeID?: number
) {
  return (
    item1.retailer.id === item2.retailer.id &&
    ((editingCardTypeID && item1.cardType.id === editingCardTypeID) ||
      item1.cardType.id === item2.cardType.id)
  );
}

export const BASKET_LOCAL_STORAGE_KEY = "basket";
export const ECODES_LOCAL_STORAGE_KEY = "eCodeDatas";
export const CUSTOMER_LOCAL_STORAGE_KEY = "customer";
export const API_RETAILER_LOCAL_STORAGE_KEY = "apiRetailer";
export const RETAILER_LOOKUP_LOCAL_STORAGE_KEY = "retailerLookup";
export const ORDER_PAGE_DATA_LOCAL_STORAGE_KEY = "orderPageData";

export default function Provider({ children }: { children: ReactNode }) {
  const [basket, updateBasket] = useLocalStorageState<ERSTypes.Basket[]>(
    BASKET_LOCAL_STORAGE_KEY,
    "[]"
  );
  const [eCodes, updateECodes] = useLocalStorageState<ERSTypes.ECodeData[]>(
    ECODES_LOCAL_STORAGE_KEY,
    "[]"
  );
  const [customer, updateCustomer] =
    useLocalStorageState<ERSTypes.Customer | null>(
      CUSTOMER_LOCAL_STORAGE_KEY,
      "null"
    );
  const [apiRetailer, updateApiRetailer] =
    useLocalStorageState<ErsApiTypes.ProductsResponse | null>(
      API_RETAILER_LOCAL_STORAGE_KEY,
      "null"
    );
  const [orderPageData, updateOrderPageData] =
    useLocalStorageState<OrderPageData | null>(
      ORDER_PAGE_DATA_LOCAL_STORAGE_KEY,
      "null"
    );
  const [retailerLookup, updateRetailerLookup] = useLocalStorageState<
    Record<string, Retailer>
  >(RETAILER_LOOKUP_LOCAL_STORAGE_KEY, "{}");

  const balance = useMemo(
    () =>
      eCodes.map((e) => e.eCode).reduce(sumValues, 0) -
      basket.reduce(sumValues, 0),
    [basket, eCodes]
  );

  function findInBasket(retailerID: string, cardTypeID: number) {
    return basket.find(
      (item) =>
        item.retailer.id === retailerID && item.cardType.id === cardTypeID
    );
  }

  function addBasketItem(basketItem: ERSTypes.Basket) {
    const existingItem = findInBasket(
      basketItem.retailer.id,
      basketItem.cardType.id
    );
    updateBasket(
      // @note: Add the values to the existing item value.
      existingItem
        ? basket.map((item) =>
            isBasketItemMatch(item, basketItem)
              ? { ...item, value: item.value + basketItem.value }
              : item
          )
        : [...basket, basketItem]
    );
  }

  function updateBasketItem(
    basketItem: ERSTypes.Basket,
    editingCardTypeID: number
  ) {
    const existingItem = findInBasket(
      basketItem.retailer.id,
      basketItem.cardType.id
    );
    updateBasket([
      // @note: Filter out any matching items.
      ...basket.filter(
        (item) => !isBasketItemMatch(item, basketItem, editingCardTypeID)
      ),
      // @note: (Re)add new one at the end.
      existingItem && basketItem.cardType.id !== editingCardTypeID
        ? { ...existingItem, value: existingItem.value + basketItem.value }
        : basketItem
    ]);
  }

  const basketValidatorData: BasketValidatorData = useMemo(
    () => ({ eCodes, basket, apiRetailer }),
    [eCodes, basket, apiRetailer]
  );

  function validateBasket(): ERSValidation.ValidationResult {
    const basketValidator = new BasketValidator();

    basketValidator.addValidator(new ECodeValidator(basketValidatorData));
    basketValidator.addValidator(new CardTypeValidator(basketValidatorData));
    basketValidator.addValidator(new RetailerValidator(basketValidatorData));
    basketValidator.addValidator(new AmountValidator(basketValidatorData));

    const validationResults = basketValidator.validate();

    return validationResults.some((r) => r.isValid === false)
      ? validationResults.find((r) => r.isValid === false)!
      : validationResults[0];
  }

  useEffect(() => {
    localStorage.setItem(BASKET_LOCAL_STORAGE_KEY, JSON.stringify(basket));
  }, [basket]);

  useEffect(() => {
    localStorage.setItem(ECODES_LOCAL_STORAGE_KEY, JSON.stringify(eCodes));
  }, [eCodes]);

  useEffect(() => {
    localStorage.setItem(CUSTOMER_LOCAL_STORAGE_KEY, JSON.stringify(customer));
  }, [customer]);

  useEffect(() => {
    localStorage.setItem(
      API_RETAILER_LOCAL_STORAGE_KEY,
      JSON.stringify(apiRetailer)
    );
  }, [apiRetailer]);

  useEffect(() => {
    localStorage.setItem(
      ORDER_PAGE_DATA_LOCAL_STORAGE_KEY,
      JSON.stringify(orderPageData)
    );
  }, [orderPageData]);

  useEffect(() => {
    localStorage.setItem(
      RETAILER_LOOKUP_LOCAL_STORAGE_KEY,
      JSON.stringify(retailerLookup)
    );
  }, [retailerLookup]);

  return (
    <Context.Provider
      value={{
        balance,
        basket,
        findInBasket,
        updateBasket,
        addBasketItem,
        updateBasketItem,
        eCodes,
        updateECodes,
        customer,
        updateCustomer,
        apiRetailer,
        updateApiRetailer,
        retailerLookup,
        updateRetailerLookup,
        orderPageData,
        updateOrderPageData,
        validateBasket
      }}
    >
      <MicroCopyProvider>
        <SnackbarProvider>{children}</SnackbarProvider>
      </MicroCopyProvider>
    </Context.Provider>
  );
}
