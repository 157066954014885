import AppInsightsLogger from "./appInsightsLogger";
import ConsoleLogger from "./consoleLogger";

class Logger implements ERSLogging.ILogger {
  private _logs: ERSLogging.ILog[];
  constructor() {
    this._logs = [new ConsoleLogger(), new AppInsightsLogger()];
  }

  minLogLevel: ERSLogging.LogLevel = (process.env.GATSBY_MIN_LOG_LEVEL ||
    "WARNING") as ERSLogging.LogLevel;
  disabled: boolean = process.env.GATSBY_DISABLE_LOGGING === "true";

  private shouldLog = (level: ERSLogging.LogLevel): boolean => {
    const logLevelMapping: Record<ERSLogging.LogLevel, ERSLogging.LogLevel[]> =
      {
        DEBUG: ["DEBUG"],
        INFO: ["DEBUG", "INFO"],
        WARNING: ["DEBUG", "INFO", "WARNING"],
        ERROR: ["DEBUG", "INFO", "WARNING", "ERROR"]
      };
    return logLevelMapping[level].includes(this.minLogLevel);
  };

  private executeAsync = async (func: () => any) => {
    return new Promise((res, rej) => {
      try {
        res(func());
      } catch (error) {
        rej(error);
      }
    });
  };

  debug = (...args: any[]) => {
    if (this.disabled || !this.shouldLog("DEBUG")) return;

    this._logs.forEach((log) => this.executeAsync(() => log.debug(...args)));
  };
  info = (...args: any[]) => {
    if (this.disabled || !this.shouldLog("INFO")) return;

    this._logs.forEach((log) => this.executeAsync(() => log.info(...args)));
  };
  warning = (...args: any[]) => {
    if (this.disabled || !this.shouldLog("WARNING")) return;

    this._logs.forEach((log) => this.executeAsync(() => log.warning(...args)));
  };
  error = (...args: any[]) => {
    if (this.disabled || !this.shouldLog("ERROR")) return;

    this._logs.forEach((log) => this.executeAsync(() => log.error(...args)));
  };
}

const logger = new Logger();

export default logger;
