import {
  Children,
  cloneElement,
  ReactElement,
  ReactNode,
  RefObject,
  useRef,
  useState
} from "react";
import { MoreVert as EllipsisIcon } from "@mui/icons-material";
import {
  AppBar,
  AppBarProps,
  Backdrop,
  IconButton,
  Menu,
  Stack,
  SxProps,
  Toolbar,
  useScrollTrigger
} from "@mui/material";
import Container from "@edenred/container";

export const HEADER_ID = "application-header";

type HeaderProps = AppBarProps & {
  icon?: ReactNode;
  menuChildren?: ReactElement[];
  toolbarRef?: RefObject<HTMLDivElement>;
  menuButtonTitle?: string;
};

const sxHeader: SxProps = {
  justifyContent: "center",
  height: {
    xs: 50,
    sm: 72
  },
  borderBottom: 1,
  borderColor: "grey.300",
  bgcolor: "common.white",
  "@media print": {
    display: "none"
  }
};

const sxToolbar: SxProps = {
  bgcolor: "common.white",
  display: "flex",
  justifyContent: "space-between",
  width: 1,
  height: 1,
  minHeight: 1,
  "& *": {
    maxHeight: {
      xs: 40,
      sm: 48
    }
  }
};

const sxBackdrop: SxProps = {
  zIndex: "speedDial", //Ensure backdrop appears below header and menu
  visibility: {
    xs: "visible",
    sm: "hidden"
  }
};

const scrolledFromTop = () =>
  useScrollTrigger({ disableHysteresis: true, threshold: 0 });

const Header = ({
  icon,
  menuChildren,
  children,
  toolbarRef = useRef<HTMLDivElement | null>(null),
  menuButtonTitle = "More options",
  ...props
}: HeaderProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const openMenu = () => setMenuOpen(true);
  const closeMenu = () => setMenuOpen(false);

  return (
    <>
      <AppBar
        id={HEADER_ID}
        sx={sxHeader}
        elevation={scrolledFromTop() ? 4 : 0}
        position="sticky"
        enableColorOnDark
        {...props}
      >
        <Container sxStyle={{ height: 1 }}>
          <Toolbar disableGutters ref={toolbarRef} sx={sxToolbar}>
            {icon}
            <Stack
              direction="row"
              spacing={1}
              sx={{ width: icon ? "min-content" : 1 }}
            >
              {children}
              {menuChildren && menuChildren.length > 0 && (
                <HeaderMenu
                  toolbarRef={toolbarRef}
                  open={menuOpen}
                  openMenu={openMenu}
                  closeMenu={closeMenu}
                  menuButtonTitle={menuButtonTitle}
                >
                  {menuChildren}
                </HeaderMenu>
              )}
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <Backdrop sx={sxBackdrop} open={menuOpen} onClick={closeMenu} />
    </>
  );
};

const sxMenu: SxProps = {
  "& .MuiMenu-list": {
    display: "flex",
    flexDirection: "column",
    alignItems: "start"
  },

  "& .MuiMenu-paper": {
    mt: 0.125,
    borderRadius: 0,
    boxShadow: 2,
    p: {
      xs: 2.5,
      sm: 0
    },
    minWidth: { xs: 1, sm: 375 },
    width: { xs: 1, sm: 375 }
  }
};

type HeaderMenuProps = {
  toolbarRef: RefObject<HTMLDivElement>;
  open: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  children: ReactElement[];
  menuButtonTitle: string;
};

const HeaderMenu = ({
  toolbarRef,
  open,
  openMenu,
  closeMenu,
  children,
  menuButtonTitle
}: HeaderMenuProps) => (
  <>
    <IconButton
      color="primary"
      title={menuButtonTitle}
      id="menu-button"
      aria-expanded={open ? "true" : undefined}
      aria-haspopup="true"
      onClick={openMenu}
    >
      <EllipsisIcon />
    </IconButton>
    <Menu
      MenuListProps={{
        "aria-labelledby": "menu-button"
      }}
      anchorEl={toolbarRef.current}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      marginThreshold={0}
      open={open}
      onClose={closeMenu}
      disableScrollLock
      sx={sxMenu}
    >
      {Children.map(children, (child) => {
        const props = {
          ...child.props,
          onClick: () => {
            if (child.props.onClick || child.props.href) {
              child.props.onClick?.();
              closeMenu();
            }
          },
          sx: {
            ...child.props.sx,
            width: 1
          }
        };
        return cloneElement(child, props);
      })}
    </Menu>
  </>
);

export default Header;
