import { BASKET_ERRORS, ValidatorLogger } from ".";
import { sumValues } from "../../money";
import BaseValidator, { BasketValidatorData } from "./baseValidator";

class AmountValidator extends BaseValidator {
  name = "AmountValidator";
  order: number = 3;
  private _logger: ValidatorLogger;

  constructor(validatorData: BasketValidatorData) {
    super(validatorData);
    this._logger = new ValidatorLogger(this.name);
  }

  validate = (): ERSValidation.ValidationResult => {
    this._logger.begin();

    const basketTotal = this._validatorData.basket.reduce(sumValues, 0);
    const eCodesTotal = this._validatorData.eCodes
      .map((e) => e.eCode)
      .reduce(sumValues, 0);

    this._logger.data({ basketTotal, eCodesTotal });

    if (basketTotal !== eCodesTotal) {
      this._logger.invalid(BASKET_ERRORS.TOTAL);
      return { isValid: false, message: BASKET_ERRORS.TOTAL };
    }

    this._logger.valid();
    return { isValid: true };
  };
}

export default AmountValidator;
