import ERSClientApi from "../api";

class AppInsightsLogger implements ERSLogging.ILog {
  debug = (...args: any[]) => {
    new ERSClientApi().logging({ level: "DEBUG", logs: args });
  };
  info = (...args: any[]) => {
    new ERSClientApi().logging({ level: "INFO", logs: args });
  };
  warning = (...args: any[]) => {
    new ERSClientApi().logging({ level: "WARNING", logs: args });
  };
  error = (...args: any[]) => {
    new ERSClientApi().logging({ level: "ERROR", logs: args });
  };
}

export default AppInsightsLogger;
