import { BASKET_ERRORS, ValidatorLogger } from ".";
import BaseValidator, { BasketValidatorData } from "./baseValidator";

class RetailerValidator extends BaseValidator {
  name = "RetailerValidator";
  order: number = 2;
  private _logger: ValidatorLogger;

  constructor(validatorData: BasketValidatorData) {
    super(validatorData);
    this._logger = new ValidatorLogger(this.name);
  }

  validate = (): ERSValidation.ValidationResult => {
    this._logger.begin();

    if (this._validatorData.apiRetailer === null) {
      this._logger.invalid(BASKET_ERRORS.GENERIC);
      return { isValid: false, message: BASKET_ERRORS.GENERIC };
    }

    const { products } = this._validatorData.apiRetailer;
    const basket = this._validatorData.basket;

    for (let i = 0; i < basket.length; i++) {
      const item = basket[i];
      const product = products.find((p) => p.id === item.retailer.referenceId);

      this._logger.data(
        { basketItem: item, product: product },
        i + 1,
        basket.length
      );

      if (!product || !product.cardTypes) {
        this._logger.invalid(BASKET_ERRORS.INVALID_RETAILER);
        return { isValid: false, message: BASKET_ERRORS.INVALID_RETAILER };
      }
    }

    this._logger.valid();
    return { isValid: true };
  };
}

export default RetailerValidator;
