import { useState } from "react";
import { isBrowser } from "./useToken";

const useLocalStorageState = <T>(
  localStorageKey: string,
  defaultValue: string
) => {
  return useState<T>(
    JSON.parse(
      (isBrowser && localStorage.getItem(localStorageKey)) || defaultValue
    )
  );
};
export default useLocalStorageState;
